.recommended-recipes {
    margin-top: 2rem;
    padding: 20px;
}

.recipe-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 2rem;
    padding: 0;
}

.recipe-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #333;
    background-blend-mode: lighten;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1.5rem;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    height: 200px;
    /* Set a fixed height for items */
}

.recipe-item h3 {
    font-size: 1.2rem;
    margin: 0;
    text-transform: uppercase;
    /* set the recipe name to uppercase */
}


.recipe-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.recipe-content {
    text-align: center;
    /* Centered the text horizontally */
}