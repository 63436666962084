.App.recipe-background {
  background-image: url("/images/food-pattern.png");
  background-repeat: repeat; 
  background-size: cover;
  background-attachment: scroll;
  background-blend-mode: lighten;
  background-color: rgba(255, 255, 255, 0.8);
}


.search-error {
    color: red;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
  }
  