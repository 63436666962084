.chat-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 2px solid #007bff; /* New border */
  padding: 15px; /* Padding to give some space between the border and the content */
}
.chat-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #007bff;
  font-size: 1.2em;
}
.chat-title h2 {
  margin: 0;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f5f5f5;
}
.chat-messages::-webkit-scrollbar {
  width: 8px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.chat-messages::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}


.chat-input-form {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e0e0e0;
  width: 100%;
  border: none;
  padding: 15px;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
}

.chat-input {
  display: flex;
  border: none;
  padding: 15px;
  font-size: 16px;
  outline: none;
  flex-grow: 1;
}

.typing-animation {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.typing-animation span {
  position: absolute;
  top: 0;
  width: 4px;
  height: 100%;
  background: #333;
  animation: typing-animation 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.typing-animation span:nth-child(2) {
  left: 6px;
  animation-delay: -0.4s;
}

.typing-animation span:nth-child(3) {
  left: 12px;
  animation-delay: -0.8s;
}

@keyframes typing-animation {
  0% {
    top: 0;
    height: 100%;
    opacity: 1;
  }
  50% {
    top: 25%;
    height: 50%;
    opacity: 0.2;
  }
  100% {
    top: 0;
    height: 100%;
    opacity: 1;
  }
}

.send-btn {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 20px;
  margin-left: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.send-btn:hover {
  background-color: #0069d9;
  border-color: #0069d9;
}
