body {
  overflow-x: hidden;
}

h1,
.recipe-title {
  color: var(--text-color);
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  text-transform: uppercase;
}

.recipe-images {
  margin-bottom: 20px;
  justify-content: 'center';
  align-items: 'center';
  width: 100%;
}
.recipe-images .carousel .slide img {
  width: 100%;
  height: auto;
}
.recipe-images .carousel .thumbs {
  overflow-x: auto;
  display: flex;
  justify-content: center;
  max-width: 100%; /* ensure the container doesn't overflow the parent */
}

.recipe-images .carousel .thumb {
  flex: 0 0 auto;
  width: 20%; /* limit each thumbnail to a percentage of the container width */
}


h1 {
  font-size: 2.5rem;
  color: #333;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

input[type="text"] {
  width: 100%;
  max-width: 400px;
  padding: 10px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%;
  max-width: 200px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.recipe-card {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  width: 100%;
}

.recipe-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.recipe-info,
.recipe-ingredients,
.recipe-instructions {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 15px;
}


.recipe-ingredients ul,
.recipe-instructions ol {
  padding-left: 20px;
}

.recipe-info {
  margin-bottom: 25px;
}

.recipe-ingredients {
  margin-bottom: 25px;
}


.loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.ingredient-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.replace-button {
  margin-left: 10px;
}

.recipe-instructions ul {
  list-style-type: none;
  padding-left: 0;
}

.recipe-instructions li {
  margin-bottom: 10px;
  padding-left: 1.5em;
  text-indent: -1.5em;
}

.recipe-instructions li::before {
  content: counter(step);
  counter-increment: step;
  margin-right: 10px;
  font-weight: bold;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.recipe-description {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.seo-text {
  font-size: 1rem;
  margin-top: 20px;
  color: #777;
  text-align: center;
}

@media (max-width: 768px) {
  .recipe-card {
    width: calc(60% - 40px); /* This line makes sure the recipe card takes partial width minus the margins on larger devices */
    margin: 20px; /* If you want margins around the card on small devices, if not, set it to 0 */
  }
  .recipe-title {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .recipe-card {
    width: calc(100% - 40px); /* This line makes sure the recipe card takes full width minus the margins on small devices */
    margin: 20px; /* If you want margins around the card on small devices, if not, set it to 0 */
  }
}
@media screen and (min-width: 768px) {
  .recipe-images .carousel .slide img {
    width: 50%; /* or whatever percentage you prefer on desktop */
  }
}
@media screen and (min-width: 768px) {
  .recipe-info, .recipe-ingredients, .recipe-instructions {
    width: 90%;
    margin: 0 auto; /* This will center your text block */
  }
}

