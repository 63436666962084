.homepage {
  margin: 0;
  background-image: url("/images/food-pattern.png");
  background-size: cover;
  background-repeat: repeat;
  background-color: rgba(255, 255, 255, 0.8);
  background-blend-mode: lighten;
}

.content {
  padding: 20px;
}

.header {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 40px;
}

.section-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.recipe-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.recipe-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  text-decoration: none;
  color: #333;
}

.recipe-item img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.recipe-item h3 {
  font-size: 1.2rem;
  margin-top: 10px;
}

.search-container {
  text-align: center;
  margin-bottom: 20px;
}

.search-input {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  margin-right: 10px;
}

.search-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.search-button:hover {
  background-color: #45a049;
}

.recipe-categories {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2em 0;
}

.recipe-category {
  flex: 1;
  min-width: 200px;
  text-align: center;
  padding: 1em;
}

.articles-header {
  display: flex;
  text-align: center;
  margin-bottom: 20px;
  justify-content: center;
}
.articles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.articles a {
  display: block;
  background: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  width: calc(33.3333% - 20px);
  padding: 20px;
  color: #333;
  text-decoration: none;
  transition: box-shadow 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.articles a:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transform: scale(1.03);
  color: #007BFF; /* change color on hover */
  text-decoration: underline; /* underline text on hover */
  cursor: pointer; /* change cursor on hover */
}

@media (max-width: 768px) {
  .articles a {
    width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .articles a {
    width: 100%;
  }
}
