body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
  display: grid;
  place-items: center;
}

:root {
  --primary-color: #4caf50;
  --secondary-color: #45a049;
  --text-color: #333;
}

button {
  background-color: var(--primary-color);
}

button:hover {
  background-color: var(--secondary-color);
}

.loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}