.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.message p {
  display: flex;
  margin: 0;
  padding: 8px 15px;
  border-radius: 15px;
  font-size: 16px;
  text-align: left;
}

.message:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.message-user {
  flex-direction: row-reverse;
}
.message-user p {
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  border-radius: 5px;
  padding: 10px;
  margin: 0 10px;
  max-width: 70%;
  word-wrap: break-word;
}

.message-assistant p {
  background-color: #f1f1f1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  color: #333;
  justify-content: flex-start;
  margin-left: 10px;
}

.message-avatar {
  display: flex;
}
.message-avatar img {
  object-fit: cover;
}


.error-message {
  color: red;
  font-weight: bold;
  padding: 10px;
  text-align: center;
}

